import { calculPrice } from "../array/CryptoRow";
import { CryptoTableRow } from "../data/CryptoDataType";

export const sumOrNull = (a: number | null | undefined, b: number | null | undefined) => {
    return ((a == null || a === undefined || isNaN(a))
        ? ((b == null || b === undefined || isNaN(b)) ? 0 : b)
        : (b == null || b === undefined || isNaN(b)) ? a : a + b);
}

export const valueFormatter = (value: number) => `$${value}`;

export const calculBarChartData = (rowsToto: any, rowsTotoTarget: any, rows: any, data: any, dataTarget: any) => {

    if (rows.items) {
        for (let item of rows.items) {
            let item4: CryptoTableRow = item as CryptoTableRow;

            let price = item4.totalPrice ? parseFloat(item4.totalPrice.toFixed(2)) : 0;
            let priceInt = item4.totalPrice ? parseInt(item4.totalPrice.toFixed(20)) : 0;
            rowsToto.push({ value: priceInt, label: item4.symbol });
            data.push({
                value: price
                , label: item4.symbol + ' $' + price
                , label2: item4.symbol
            });

            let toto = {
                maxTarget: 0, value: price, id: item4.symbol + Math.random()
                , label: item4.symbol + ' $' + price
                , label2: item4.symbol
            };
            let item3: CryptoTableRow = item as CryptoTableRow;
            let total = 0;
            let sumPerc = 0;

            for (let i = 0; i < item3.targets.length; i++) {
                let rate = (item3.targets[i].rate == null || item3.targets[i].rate === undefined ? null : item3.targets[i].rate);
                let targetPrice = calculPrice(item3.targets[i].bag, rate);
                total += Number(targetPrice ? targetPrice.toFixed(20) : 0);
                let bagPerc = item3.targets[i].bagPerc === undefined ? 0 : item3.targets[i].bagPerc;
                if (bagPerc !== undefined) {
                    sumPerc += bagPerc;
                }

            }
            if (sumPerc < 100 && item3.targets.length > 0) {
                let rate = (item3.targets[item3.targets.length - 1].rate == null || item3.targets[item3.targets.length - 1].rate === undefined ? null : item3.targets[item3.targets.length - 1].rate);
                let bag = item3.targets[item3.targets.length - 1].bag;
                if (bag !== undefined) {
                    let targetPrice = calculPrice(bag * (100 - sumPerc) / 100, rate);
                    total += Number(targetPrice ? targetPrice.toFixed(20) : 0);
                }

            }
            let totalInt = parseInt(total.toFixed(20));
            rowsTotoTarget.push({ value: totalInt, label: item3.symbol });
            toto.maxTarget = total;
            dataTarget.push(toto);
        }
    }
    return dataTarget;
}

export const groupBarChartData = (data: any, dataTarget: any,groupedData:any,groupedDataTarget:any) =>{
    for (let item of data) {
        let trouve=false;
        for (let item2 of groupedData) {
            if(item2.label2===item.label2){
trouve=true;
                item2.price +=item.price;
                item2.label= item2.label2+ ' $' + item2.price;
            }
        }

        if(!trouve){
            groupedData.push(item);
        }
    }

    for (let item of dataTarget) {
        let trouve=false;
        for (let item2 of groupedDataTarget) {
            if(item2.label2===item.label2){
trouve=true;
                item2.price +=item.price;
                item2.label= item2.label2+ ' $' + item2.price;
                item2.maxTarget += item.maxTarget;
            }
        }

        if(!trouve){
            groupedDataTarget.push(item);
        }
    }
}

export const calculPieData = (rowsToto: any, rowsTotoTarget: any, rows: any, data: any, dataTarget: any) => {
    for (let item of rows.items) {
        let item3: CryptoTableRow = item as CryptoTableRow;
        let price = item3.totalPrice ? parseFloat(item3.totalPrice.toFixed(2)) : 0;
        let priceInt = item3.totalPrice ? parseInt(item3.totalPrice.toFixed(20)) : 0;
        rowsToto.push({ value: priceInt, label: item3.symbol });
        data.push({
            value: price
            , label: item3.symbol + ' $' + price
            , label2: item3.symbol
        });
    }

    for (let item of rows.items) {
        let item3: CryptoTableRow = item as CryptoTableRow;
        let total = 0;
        let sumPerc = 0;
        for (let i = 0; i < item3.targets.length; i++) {
            let rate = (item3.targets[i].rate == null || item3.targets[i].rate === undefined ? null : item3.targets[i].rate);
            let targetPrice = calculPrice(item3.targets[i].bag, rate);
            total += Number(targetPrice ? targetPrice.toFixed(20) : 0);
            let bagPerc = item3.targets[i].bagPerc;
            if (bagPerc !== undefined) {
                sumPerc += bagPerc;
            }
        }
        if (sumPerc < 100 && item3.targets.length > 0) {
            let rate = (item3.targets[item3.targets.length - 1].rate == null || item3.targets[item3.targets.length - 1].rate === undefined ? null : item3.targets[item3.targets.length - 1].rate);
            let bag = item3.targets[item3.targets.length - 1].bag;
            if (bag !== undefined) {
                let targetPrice = calculPrice(bag * (100 - sumPerc) / 100, rate);
                total += Number(targetPrice ? targetPrice.toFixed(20) : 0);
            }
        }
        let totalInt = parseInt(total.toFixed(20));
        rowsTotoTarget.push({ value: totalInt, label: item3.symbol });
        dataTarget.push({
            value: total, label: item3.symbol + ' $' + totalInt
            , label2: item3.symbol
        });


    }
}

export const cloneItems = (props: any, rows: any) => {
    let copyItems = JSON.parse(JSON.stringify(props.rows.items));
    for (let item of copyItems) {
        let trouve = false;
        if (!trouve) {
            (rows.items as any).push({ ...item });//clone
        }
    }
}