import "./Crypto.css";
import CryptoRowBuyPopup from "./array/CryptoRowBuyPopup";
import CryptoTable from "./array/CryptoTable";
import { DISPLAY_MODE_ARRAY, DISPLAY_MODE_GRAPH } from "./data/CryptoConfig";
import CryptoPie from "./graph/CryptoPie";
import { isCookieAlreadyRead } from "./data/CryptoCookie";
import Bubbles from "./bubble/Bubbles";


export default function CryptoContent(object: any) { 

  if (object.buyPopupRef.current!=null && !isCookieAlreadyRead()){
    (object.buyPopupRef.current as any).handleOpen("CookieMode");
  }

  return (
    <>
      {object.cryptoRows && object.cryptoRows.items && object.cryptoRows.items.length>0 && <Bubbles coins={object.cryptoRows}/>}
      
      {object.displayMode===DISPLAY_MODE_ARRAY && <CryptoTable cryptoRows={object} 
      buyPopupRef={object.buyPopupRef}
      partialRefresh={object.partialRefresh} fullRefresh={object.fullRefresh}  
      viewMode={object.viewMode}/>}
                    
    
     {object.displayMode===DISPLAY_MODE_GRAPH && <CryptoPie rows={object.cryptoRows} />}
      </>
  );
}
