import React, { useState } from "react";
import { getPositionInPorttcfolio, porttcfolio, updatePorttcfolio } from "../data/CryptoData";
import { reprocessTargetsData } from "../data/CryptoProcessData";
import { Tooltip } from "@mui/material";

const TargetEditor = (val: any) => {
    let [value] = useState(val);

    const blurMe = (newValue: any) => {
        value.val.targets[value.targetPos][value.prop] = newValue;
        reprocessTargetsData(value.val);
        let pos = getPositionInPorttcfolio(value.val.ttcId, porttcfolio);
        porttcfolio.items[pos] = value.val;
        let strPorttcfolio = JSON.stringify(porttcfolio);
        updatePorttcfolio(strPorttcfolio);
        localStorage.setItem("cryptool", strPorttcfolio);
    }

    const focusMe = () => {
    }

    let myClassName = "fieldEditor " + val.type;

    if (value.viewMode) {
        return <Tooltip className={"fieldEditor"} title={val.tooltip}><span className={"fieldEditor viewMode "+val.className}>{val.type === "currency" && value.val.targets[value.targetPos][value.prop] ? "$" : ""}{value.val.targets[value.targetPos][value.prop]}{val.type === "percentage" && value.val.targets[value.targetPos][value.prop] ? "%" : ""}</span></Tooltip>
    } else {
        return (<Tooltip className={"fieldEditor"} title={val.tooltip}><span className={"fieldEditor editMode "+val.className}>{val.type === "currency" ? "$" : ""}<input type="text" defaultValue={value.val.targets[value.targetPos][value.prop]}
            onFocus={e => focusMe()}
            onBlur={e => blurMe(e.target.value)} className={myClassName} />{val.type === "percentage" ? "%" : ""}</span></Tooltip>);
    }
}



export default TargetEditor;
