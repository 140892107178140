import CryptoRow from "./CryptoRow";


const CrytoGroupedRowsPerCrypto = (props: any) => {
    let items= props.items;
    let rows=props.rows;
    let result=[];
    for(let i=0;i<items.length;i++){
        let row=items[i];
        let firstRow=(i==0?"firstRow":"");
        result.push(<CryptoRow pos={i} item={row} rows={rows.items} firstRow={firstRow}
            groupedRowsCount={items.length}
            buyPopupRef={props.buyPopupRef}
            viewMode={props.viewMode}
            partialRefresh={props.partialRefresh} fullRefresh={props.fullRefresh}
            onAddTarget={props.toggleUpdated}
            onRemoveTarget={props.onRemoveTarget}
            onRemove={props.onRemove} key={"cryptorow" + row.name + row.site + Math.random()} />);
    }

    return (<>{result}</>);
}

export default CrytoGroupedRowsPerCrypto;