import React from "react";
import "../CryptoTable.css";
import { BarChart } from "@mui/x-charts";
import { CryptoTableRow, CryptoTableRows, colors2 } from "../data/CryptoDataType";
import { Row, Col, Table } from "reactstrap";
import { calculBarChartData, calculPieData, cloneItems, sumOrNull, valueFormatter } from "./CryptoGraphUtils";
import { TableBody } from "@mui/material";

interface ChildProps {
    rows: CryptoTableRows;
    tutu?: any;
}

const chartSetting = {
    xAxis: [
        {
            label: 'Montant (USDT)',
        },
    ],
    width: 1000,
    height: 800,
};

let rowsToto: any = [];
let rowsTotoTarget: any = [];

const renderBarChart = (data: any) => {

    let total=0;
    let totalTarget=0;
    for (let item of data) {
        total+=item.value;
        totalTarget+=item.maxTarget;
    }
    return <BarChart
        dataset={data}
        colors={colors2}
        yAxis={[{ scaleType: 'band', dataKey: 'label2' }]}
        series={[
            { dataKey: 'value', label: 'Montant actuel ('+total.toFixed(0)+' USDT)', valueFormatter: valueFormatter },
            { dataKey: 'maxTarget', label: 'Objectif ('+totalTarget.toFixed(0)+' USDT)', valueFormatter: valueFormatter }
        ]}
        layout="horizontal"
        {...chartSetting}
    />;
}

const CryptoMultiBarPerSymbol: React.FC<ChildProps> = (props: ChildProps) => {

    let rows = { items: [] };
    cloneItems(props,rows);

    let barChartData:any[]=[];
    calculBarChartData(rowsToto, rowsTotoTarget, rows,[],barChartData);

    return (
        <span>
            <Table className="cryptoPie" id="cryptoPie">
                <TableBody>
                <Row className="cryptoPieRow">
                    <Col md="12" className="cryptoPieItemExpected">
                        Portfolio
                    </Col>
                </Row>
                <Row className="cryptoPieRow">
                    <Col md="12" className="cryptoBarItem">
                        {renderBarChart(barChartData)}
                    </Col>
                </Row>
                </TableBody>
            </Table></span>);
};

export default CryptoMultiBarPerSymbol;