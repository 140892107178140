import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React from "react";
import { StatusEnum } from "../data/CryptoDataType";
import { reprocessTargetsData } from "../data/CryptoProcessData";
import { getPositionInPorttcfolio, porttcfolio, updatePorttcfolio } from "../data/CryptoData";

interface IProps {
   targetPos:any;
    val:any;
}
interface IState {
  value:StatusEnum;
}


class StatusEditor extends React.Component<IProps,IState> {

  constructor(props: any) {
    super(props);
    let initValue=this.props.val.targets[this.props.targetPos]["status"]==null?StatusEnum.Init:(this.props.val.targets[this.props.targetPos]["status"]?this.props.val.targets[this.props.targetPos]["status"]:StatusEnum.Init);
    this.state = {
      value:initValue
    };
  }

  

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue=Number((event.target as HTMLInputElement).value);

    this.props.val.targets[this.props.targetPos]["status"]=newValue;
    if(newValue===StatusEnum.Sold){
      this.props.val.targets[this.props.targetPos]["sold"]=true;
    }else{
      this.props.val.targets[this.props.targetPos]["sold"]=false;

    }
    

  reprocessTargetsData(this.props.val);
  let pos=getPositionInPorttcfolio(this.props.val.ttcId,porttcfolio);
  porttcfolio.items[pos]=this.props.val;

  let strPorttcfolio=JSON.stringify(porttcfolio);
  updatePorttcfolio(strPorttcfolio);
  localStorage.setItem("cryptool",strPorttcfolio);
  this.setState({value:newValue});
}


  render() {
    let targetData= this.props.val.targets[this.props.targetPos]["status"];
    return (
      <RadioGroup
      className="statusEditor"
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name={"row-radio-buttons-group-"+this.props.val.ttcId+"-"+this.props.targetPos+"-"+Math.random()}
        value={targetData}
        onChange={this.onChange}
      >
        <FormControlLabel value={StatusEnum.Init} control={<Radio />}  style={{whiteSpace:"nowrap",marginRight:"0px"}} label="Initial" />
        <FormControlLabel value={StatusEnum.Order} control={<Radio />}  style={{whiteSpace:"nowrap",marginRight:"0px"}} label="Ordre de vente" />
        <FormControlLabel value={StatusEnum.Sold} control={<Radio />}  style={{whiteSpace:"nowrap",marginRight:"0px"}} label="Vendu" />
      </RadioGroup>

    );

  }

}



export default StatusEditor;

