import { useRef, useState } from "react";
import "./CryptoRowTargetScroller.css";
import { CryptoTableRow, StatusEnum, TargetData, isStableCoin, usedBagPerc } from "../data/CryptoDataType";
import CryptoRowTarget from "./CryptoRowTarget";
import { IconButton, TableBody, TableCell, TableRow, Tooltip } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import CryptoRowTargetSummary from "./CryptoRowTargetSummary";

interface IProps {
  item: any;
  rows: any;
  viewMode: any;
  onRemoveTarget: any;
  partialRefresh: any;
  fullRefresh: any;
  onAddTarget: any;
}

const CryptoRowTargetScroller: React.FC<IProps> = ({ item, rows, viewMode, onRemoveTarget, partialRefresh, fullRefresh, onAddTarget }) => {


  let targets = [];
  if (item) {

    let row: CryptoTableRow = item;
    if (isStableCoin(row.symbol)) {
      row.targets = [];
    }

    for (let i = 0; i < row.targets.length; i++) {
      targets.push(<CryptoRowTarget key={"key02" + item.name + item.site + Math.random()}
        row={row} targetPos={i} item={item} onAdd={onAddTarget}
        rows={rows}
        viewMode={viewMode}
        onRemoveTarget={onRemoveTarget}
        partialRefresh={partialRefresh} fullRefresh={fullRefresh}
      />);
    }

  }
    const elementRef = useRef(null);
    const [arrowDisable, setArrowDisable] = useState(true);

    const handleHorizantalScroll = (element: any, speed: any, distance: any, step: any) => {

      let scrollAmount = 0;
      const slideTimer = setInterval(() => {
        element.scrollLeft += step;
        scrollAmount += Math.abs(step);
        if (scrollAmount >= distance) {
          clearInterval(slideTimer);
        }
        if (element.scrollLeft === 0) {
          setArrowDisable(true);
        } else {
          setArrowDisable(false);
        }
      }, speed);
    };

    let isToAdd = false;
    let row: CryptoTableRow = item;
    let bagPerc = usedBagPerc(row);
    if (bagPerc < 100) {
      if (isStableCoin(item.symbol)) {
        targets.push(<TableCell key={"key01" + item.name + item.site + Math.random()} align="right" className="addTarget"></TableCell>);
      } else {
        isToAdd = true;
      }
    }

    const onAddTarget2 = () =>{
      let targetData:TargetData ={
        rate:0,
        price:0,
        sold: false,
        bag:0,
        bagPerc:0,
        perc:null,
        status:StatusEnum
        .Init,
        graphProcessed:false,
        notificationHigh0:60,
        notificationHigh1:70,
        notificationHigh2:80
      }
      
    row.targets.push(targetData);
      onAddTarget();
    }


    return (
        <div className={"rowTargets-container" + ((targets.length-1) > 4 ? " show" : " hide")}>
          <button
          className="rowTargets-container-inside"
            onClick={() => {
              handleHorizantalScroll(elementRef.current, 25, 100, -10);
            }}
            disabled={arrowDisable}
          >
            &lt;
          </button>
          <div className="rowTarget-container" ref={elementRef}>
            <table>
              <TableBody>
              <TableRow>
            {targets}
            </TableRow>
            </TableBody>
            </table>
          </div>
          <button
          className="rowTargets-container-inside"
            onClick={() => {
              handleHorizantalScroll(elementRef.current, 25, 100, 10);
            }}
          >
            &gt;
          </button>
          {
            isToAdd && (!viewMode) && <Tooltip title="Ajouter objectif">
              <IconButton onClick={onAddTarget2} className="addTarget" >
                <AddCircle />
              </IconButton>
            </Tooltip>
          }
        </div>
    );
  };
  export default CryptoRowTargetScroller;
